import { NestedEndpoints } from './nested-endpoints';

export function EndpointsFactory<T extends NestedEndpoints>(endpoints: T, prefix = ''): T {
  function mapEndpoints(obj: NestedEndpoints, pathPrefix: string): NestedEndpoints {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      if (typeof value === 'string') {
        acc[key] = `${pathPrefix}/${value}`;
      } else if (typeof value === 'function') {
        acc[key] = (...params: string[]) => `${pathPrefix}/${value(...params)}`;
      } else {
        acc[key] = mapEndpoints(value, pathPrefix);
      }
      return acc;
    }, {} as NestedEndpoints);
  }

  return mapEndpoints(endpoints, prefix) as T;
}
