import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { GoogleAutocompleteOptions } from './google-autocomplete.options';
import { GoogleAutocompleteService } from './google-autocomplete.service';

@Directive({
  selector: '[spxGPAutocomplete]',
  exportAs: 'spx-places',
  standalone: true,
})
export class GooglePlacesAutocompleteDirective implements AfterViewInit, OnDestroy {
  @Input() options: GoogleAutocompleteOptions;
  @Output() addressChange: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter();

  public place: google.maps.places.PlaceResult;
  private eventListener: google.maps.MapsEventListener;
  private autocomplete: google.maps.places.Autocomplete;

  constructor(
    public el: ElementRef,
    private gpAutocompleteService: GoogleAutocompleteService,
    private loader: Loader,
    private ngZone: NgZone,
  ) {}

  ngAfterViewInit(): void {
    if (!this.options) {
      this.options = this.gpAutocompleteService.getOptions();
    }

    if (this.gpAutocompleteService.getGooglePersistenceCheck()) {
      if (!this.isGoogleLibExisting()) {
        this.loader.importLibrary('places').then(() => this.initialize(), console.error);
      }
    } else {
      this.loader.importLibrary('places').then(() => this.initialize(), console.error);
    }
  }

  ngOnDestroy() {
    this.eventListener.remove();
  }

  private isGoogleLibExisting(): boolean {
    return !(!google || !google.maps || !google.maps.places);
  }

  private initialize(): void {
    if (!this.isGoogleLibExisting()) throw new Error('Google maps library can not be found');

    this.setupAutocomplete();
    this.setupKeydownHandler();
    this.fixIOSAutocompleteTouchIssue();
  }

  private setupAutocomplete(): void {
    this.autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, this.options);

    if (!this.autocomplete) throw new Error('Autocomplete is not initialized');

    if (this.autocomplete.addListener) {
      this.eventListener = this.autocomplete.addListener('place_changed', () => {
        this.handleChangeEvent();
      });
    }
  }

  private setupKeydownHandler(): void {
    this.el.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
      if (!event.key) {
        return;
      }

      if (event.key?.toLowerCase() === 'enter' && event.target === this.el.nativeElement) {
        event.preventDefault();
        event.stopPropagation();
      }
    });
  }

  private fixIOSAutocompleteTouchIssue(): void {
    if (!this.isIOSDevice()) return;

    setTimeout(() => {
      const containers = document.getElementsByClassName('pac-container');
      Array.from(containers).forEach((container) => {
        container?.addEventListener('touchend', (e) => e.stopImmediatePropagation());
      });
    }, 500);
  }

  private isIOSDevice(): boolean {
    return !!(window && window.navigator && window.navigator.userAgent && navigator.userAgent.match(/(iPad|iPhone|iPod)/g));
  }

  public reset(): void {
    this.autocomplete.setComponentRestrictions(this.options.componentRestrictions || null);
    this.autocomplete.setTypes(this.options.types || null);
  }

  private handleChangeEvent(): void {
    this.ngZone.run(() => {
      this.place = this.autocomplete.getPlace();

      if (this.place) {
        this.addressChange.emit(this.place);
      }
    });
  }
}
